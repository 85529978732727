import React, { useEffect, useState } from 'react'
import { allMonthsLanguageTitle, allYearsLanguageTitle, aprilLanguageTitle, augustLanguageTitle, decemberLanguageTitle, enterASearchTermLanguageTitle, februaryLanguageTitle, getDefaultLang, januaryLanguageTitle, julyLanguageTitle, juneLanguageTitle, marchLanguageTitle, mayLanguageTitle, novemberLanguageTitle, septemberLanguageTitle, оctoberLanguageTitle } from '../../language'
import { CATEGORIES_COLLECTION, GAMES_COLLECTION } from '../../firebase'

// redux
import { useDispatch, useSelector } from 'react-redux'
import { filterAction } from '../../redux/actions'
import { categoryFilterSelector, monthFilterSelector, yearFilterSelector, showFilterSelector } from '../../redux/selectors'

// style
import './style.css'

// media
import filter from './assets/filter.svg'
import close from './assets/close.svg'

const monthsArray = [
    { title: allMonthsLanguageTitle, value: '' },
    { title: januaryLanguageTitle, value: '01' },
    { title: februaryLanguageTitle, value: '02' },
    { title: marchLanguageTitle, value: '03' },
    { title: aprilLanguageTitle, value: '04' },
    { title: mayLanguageTitle, value: '05' },
    { title: juneLanguageTitle, value: '06' },
    { title: julyLanguageTitle, value: '07' },
    { title: augustLanguageTitle, value: '08' },
    { title: septemberLanguageTitle, value: '09' },
    { title: оctoberLanguageTitle, value: '10' },
    { title: novemberLanguageTitle, value: '11' },
    { title: decemberLanguageTitle, value: '12' }
]

const yearsArray = [
    { title: allYearsLanguageTitle, value: '' },
    { title: '2021', value: '2021' },
    { title: '2020', value: '2020' },
    { title: '2019', value: '2019' },
    { title: '2018', value: '2018' },
    { title: '2017', value: '2017' },
    { title: '2016', value: '2016' },
    { title: '2015', value: '2015' }
]

const Filter = () => {

    const defaultLang = getDefaultLang()

    const dispatch = useDispatch()

    const categoryFilter = useSelector(categoryFilterSelector)
    const monthFilter = useSelector(monthFilterSelector)
    const yearFilter = useSelector(yearFilterSelector)
    const showFilter = useSelector(showFilterSelector)

    const [categories, setCategories] = useState([])

    const [games, setGames] = useState([])

    useEffect(() => {

        const getCategories = async () => {

            const snapshot = await CATEGORIES_COLLECTION.get()

            const items = []

            snapshot.forEach(doc => {

                const data = doc.data()

                const _id = data._id
                const category = data.category

                let title = ''

                if (defaultLang === 'en') {
                    title = data.title_en
                }

                if (defaultLang === 'sr') {
                    title = data.title_sr
                }

                items.push({ _id, category, title })
            })

            setCategories(items)
        }

        getCategories()

        const getGames = async () => {

            const snapshot = await GAMES_COLLECTION.get()

            const items = []

            snapshot.forEach(doc => {

                const data = doc.data()

                const _id = data._id
                const category = data.category

                items.push({ _id, category })
            })

            setGames(items)
        }

        getGames()

    }, [])

    return (
        <>
            <div className="filter">

                <div className="filter__input-icon">
                    <input
                        className="filter__input"
                        type="text"
                        placeholder={enterASearchTermLanguageTitle}
                        onChange={(e) => dispatch(filterAction({ search: e.target.value }))}
                    />

                    {!showFilter ?
                        <img src={filter} alt="filter" className="filter__icon-filter" onClick={() => dispatch(filterAction({ show: true }))} />
                        :
                        <img src={close} alt="filter" className="filter__icon-close" onClick={() => dispatch(filterAction({ show: false }))} />
                    }
                </div>

                {showFilter &&
                    <>

                        <select className="filter__select display-block-lg" onChange={(e) => dispatch(filterAction({ category: e.target.value }))}>
                            {
                                categories.map(itemCategory => {
                                    return (
                                        <>
                                            {
                                                games.filter(itemGame => itemGame.category.includes(itemCategory.category)).length > 0
                                                    ?
                                                    <>
                                                        <option
                                                            key={itemCategory._id}
                                                            value={itemCategory.category}
                                                            selected={categoryFilter === itemCategory.category}
                                                        >
                                                            {itemCategory.title}
                                                        </option>
                                                    </>
                                                    :
                                                    null
                                            }
                                        </>
                                    )
                                })
                            }
                        </select>

                        <select className="filter__select" onChange={(e) => dispatch(filterAction({ year: e.target.value }))}>
                            {
                                yearsArray.map(item => {
                                    return (
                                        <option
                                            key={item.value}
                                            value={item.value}
                                            selected={yearFilter === item.value}
                                        >
                                            {item.title}
                                        </option>
                                    )
                                })
                            }
                        </select>

                        <select className="filter__select" onChange={(e) => dispatch(filterAction({ month: e.target.value }))}>
                            {
                                monthsArray.map(item => {
                                    return (
                                        <option
                                            key={item.value}
                                            value={item.value}
                                            selected={monthFilter === item.value}
                                        >
                                            {item.title}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </>
                }
            </div>
        </>
    )
}

export default Filter
