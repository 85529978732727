// Filter

export const searchFilterSelector = state => state?.filterReducer?.search;

export const categoryFilterSelector = state => state?.filterReducer?.category;

export const monthFilterSelector = state => state?.filterReducer?.month;

export const yearFilterSelector = state => state?.filterReducer?.year;

export const showFilterSelector = state => state?.filterReducer?.show;