import React, { useEffect, useState } from 'react'
import { categoriesLanguageTitle, getDefaultLang } from '../../language'
import { CATEGORIES_COLLECTION, GAMES_COLLECTION } from '../../firebase'

import { useDispatch, useSelector } from 'react-redux'
import { categoryFilterSelector } from '../../redux/selectors'
import { filterAction } from '../../redux/actions'

// style
import './style.css'

const Categories = () => {

    const defaultLang = getDefaultLang();

    const dispatch = useDispatch()

    const categoryFilter = useSelector(categoryFilterSelector)

    const [categories, setCategories] = useState([])

    const [games, setGames] = useState([])

    useEffect(() => {

        const getCategories = async () => {


            const snapshot = await CATEGORIES_COLLECTION.get()

            const items = []

            snapshot.forEach(doc => {

                const data = doc.data()

                const _id = data._id
                const category = data.category

                let title = ''

                if (defaultLang === 'en') {
                    title = data.title_en
                }

                if (defaultLang === 'sr') {
                    title = data.title_sr
                }

                items.push({ _id, category, title })
            })

            setCategories(items)
        }

        getCategories()

        const getGames = async () => {

            const snapshot = await GAMES_COLLECTION.get()

            const items = []

            snapshot.forEach(doc => {

                const data = doc.data()

                const _id = data._id
                const category = data.category

                items.push({ _id, category })
            })

            setGames(items)
        }

        getGames()

    }, [])
    return (
        <>
            <div className="categories">
                <h2 className="categories__title">
                    {categoriesLanguageTitle}:
                </h2>
                {
                    categories.map(itemCategory => {
                        return (
                            <>
                                {
                                    games.filter(itemGame => itemGame.category.includes(itemCategory.category)).length > 0
                                        ?
                                        <>
                                            <button
                                                key={itemCategory._id}
                                                className={`categories__button ${categoryFilter === itemCategory.category ? 'categories__button--active' : null}`}
                                                onClick={() => dispatch(filterAction({ category: itemCategory.category }))}
                                            >
                                                {itemCategory.title}
                                            </button>
                                        </>
                                        :
                                        null
                                }
                            </>
                        )
                    })
                }
            </div>
        </>
    )
}

export default Categories
