import React from 'react'
import { loadMoreLanguageTitle, ofLanguageTitle, resultsLanguageTitle } from '../../language'

import './style.css'

const ButtonLoadMore = ({ onClick, itemsPerPage, totalItems }) => {
    return (
        <>
            <button className='button-load-more' onClick={onClick}>
                {`${loadMoreLanguageTitle} (${itemsPerPage ? itemsPerPage : '-'} ${ofLanguageTitle} ${totalItems ? totalItems : '-'} ${resultsLanguageTitle})`}
            </button>
        </>
    )
}

export default ButtonLoadMore
