import React from 'react';

// style
import './style.css';

const Loader = () => {
    return (
        <>
            <div className="loader">
                <div className="progress-bar-loader" />
            </div>
        </>
    )
}

export default Loader;
