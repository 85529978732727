import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyD7sFvpnIDIpfBAU9R1dIdlkYgzoMB6KXA",
    authDomain: "soniteka-app.firebaseapp.com",
    projectId: "soniteka-app",
    storageBucket: "soniteka-app.appspot.com",
    messagingSenderId: "950145036806",
    appId: "1:950145036806:web:5624fd63270b02bc7a0987"
}

firebase.initializeApp(firebaseConfig)

const firestore = firebase.firestore()

export const serverTimestamp = firebase.firestore.Timestamp

export const CATEGORIES_COLLECTION = firestore.collection('categories')
export const GAMES_COLLECTION = firestore.collection('games')
export const FAQ_COLLECTION = firestore.collection('faq')
export const NEWS_COLLECTION = firestore.collection('news')
export const ORDERS_COLLECTION = firestore.collection('orders')

export default firebase