import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { showMoreLanguageTitle } from '../../language'

import './style.css'

const RowScroll = ({ scroll, url, title, children }) => {
    return (
        <>
            {scroll ?
                <>
                    <Row>
                        <Col lg={12}>
                            <div className='row-scroll__wrapper'>
                                <h2 className='row-scroll__title'>
                                    {title}
                                </h2>
                                <Link to={`/category/${url}`}>
                                    <button className='row-scroll__button'>
                                        {showMoreLanguageTitle}
                                    </button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row className='row-scroll'>
                        {children}
                    </Row>
                </>
                :
                <>
                    <Row>
                        <Col lg={12}>
                            <h2 className='row-scroll__title'>
                                {title}
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        {children}
                    </Row>
                </>
            }

        </>
    )
}

export default RowScroll
