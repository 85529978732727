import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { AskUsaQuestionLanguageTitle, faqLanguageTitle, getDefaultLang } from '../../language'
import { FAQ_COLLECTION, GAMES_COLLECTION } from '../../firebase'

import Content from '../../components/Content'
import IntroImage from '../../components/IntroImage';
import Group from '../../components/Group';
import CardSlider from '../../components/CardSlider'
import CardSidebar from '../../components/CardSidebar'
import Slider from '../../components/Slider'
import Loader from '../../components/Loader'
import Banner from '../../components/Banner'
import Layout from '../../components/Layout'

import faqImage from './assets/faq.jpg'

const Faq = () => {

    const defaultLang = getDefaultLang()

    const [faq, setFaq] = useState([])

    const [games, setGames] = useState([])

    useEffect(() => {

        const getFaq = async () => {

            const snapshot = await FAQ_COLLECTION
                .orderBy('date', 'desc')
                .get()

            const items = [];

            snapshot.forEach(doc => {

                const data = doc.data()

                const _id = data._id

                let title = ''
                let description = ''

                if (defaultLang === 'en') {
                    description = data.description_en
                    title = data.title_en
                }

                if (defaultLang === 'sr') {
                    description = data.description_sr
                    title = data.title_sr
                }

                items.push({ _id, description, title })
            })

            setFaq(items)
        }

        getFaq()

        const getGames = async () => {

            const snapshot = await GAMES_COLLECTION
                .where("slider", "==", true)
                .orderBy('date', 'desc')
                .get()

            const items = [];

            snapshot.forEach(doc => {

                const data = doc.data()

                const _id = data._id
                const image = data.image
                const url = data.url

                let description = ''
                let title = ''

                if (defaultLang === 'en') {
                    description = data.description_en
                    title = data.title_en
                }

                if (defaultLang === 'sr') {
                    description = data.description_sr
                    title = data.title_sr
                }

                items.push({ _id, image, url, description, title })
            })

            setGames(items)
        }

        getGames()

    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [window.location.pathname])

    return (
        <Layout navbarEmty>
            <Loader />
            <IntroImage title={faqLanguageTitle} image={faqImage} />
            <Content>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={4}>
                            <div className='display-block-lg'>
                                <CardSidebar title={AskUsaQuestionLanguageTitle}>
                                    <a href="https://www.instagram.com/ajnason1_soniteka/" target="_blank" rel="noopener noreferrer">Instagram</a>
                                    <a href="https://www.facebook.com/AJ-NA-SON1-PS4-igraonica-i-kafe-bar-Banjaluka-364225951100100/" target="_blank" rel="noopener noreferrer">Facebook</a>
                                    <a href="mailto:rezervacije@soniteka.com">E-mail</a>
                                </CardSidebar>
                                <Slider>
                                    {
                                        games
                                            .map(item => {
                                                return (
                                                    <div key={item._id}>
                                                        <CardSlider
                                                            url={`/game/${item.url}`}
                                                            image={item.image}
                                                            title={item.title}
                                                            description={item.description}
                                                        />
                                                    </div>
                                                )
                                            })
                                    }
                                </Slider>
                                <Banner />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={8}>
                            {
                                faq.map(item => {
                                    return (
                                        <Group
                                            key={item._id}
                                            title={item.title}
                                            description={item.description}
                                        />
                                    )
                                })
                            }
                            <div className='display-block-sm'>
                                <CardSidebar title={AskUsaQuestionLanguageTitle}>
                                    <a href="https://www.instagram.com/ajnason1_soniteka/" target="_blank" rel="noopener noreferrer">Instagram</a>
                                    <a href="https://www.facebook.com/AJ-NA-SON1-PS4-igraonica-i-kafe-bar-Banjaluka-364225951100100/" target="_blank" rel="noopener noreferrer">Facebook</a>
                                    <a href="mailto:rezervacije@soniteka.com">E-mail</a>
                                </CardSidebar>
                                <Slider>
                                    {
                                        games
                                            .map(item => {
                                                return (
                                                    <div key={item._id}>
                                                        <CardSlider
                                                            url={`/game/${item.url}`}
                                                            image={item.image}
                                                            title={item.title}
                                                            description={item.description}
                                                        />
                                                    </div>
                                                )
                                            })
                                    }
                                </Slider>
                                <Banner />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Content>
        </Layout>
    )
}

export default Faq
