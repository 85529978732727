import React from 'react'
import { default as Slick } from 'react-slick'

const Slider = ({ children }) => {

    const sliderSettings = {
        adaptiveHeight: false,
        arrows: false,
        autoplaySpeed: 10000,
        autoplay: true,
        dots: false,
        draggable: true,
        easing: 'linear',
        fade: false,
        focusOnSelect: true,
        infinite: true,
        pauseOnDotsHover: true,
        pauseOnFocus: true,
        pauseOnHover: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        speed: 500,
        swipe: true,
        touchMove: true,
    }

    return (
        <>
            <Slick {...sliderSettings}>
                {children}
            </Slick>
        </>
    )
}

export default Slider
