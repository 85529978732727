import React from 'react'

// style
import './style.css'

const Content = ({ children, home }) => {
    return (
        <>
            {
                home ?
                    <div className="content-home">
                        {children}
                    </div>
                    :
                    <div className="content">
                        {children}
                    </div>
            }
        </>
    )
}

export default Content
