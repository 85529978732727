import React from 'react'
import { Link } from 'react-router-dom'

import './style.css'

const IntroVideo = ({ title, url, titleButton, titleButtonOne, titleButtonTwo, onClickOne, onClickTwo, poster, video }) => {
    return (
        <>
            <div className="intro-video">
                <div className="intro-video__title-button-wrapper">
                    <h2 className='intro-video__title'>
                        {title}
                    </h2>
                    {
                        url
                            ?
                            <Link to={url}>
                                <button className='intro-video__button'>
                                    {titleButton}
                                </button>
                            </Link>
                            :
                            <>
                                <button style={{ margin: '0 10px 0 0' }} className='intro-video__button' onClick={onClickOne}>{titleButtonOne}</button>
                                <button style={{ margin: '0 0 0 10px' }} className='intro-video__button' onClick={onClickTwo}>{titleButtonTwo}</button>
                            </>



                    }
                </div>
                <div className="intro-video__overlay"></div>
                <video
                    className="intro-video__video"
                    poster={poster}
                    height="100%"
                    width="100%"
                    autoPlay={true}
                    loop
                    muted
                    preload="none"
                    playsInline
                >
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </>
    )
}

export default IntroVideo
