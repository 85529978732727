import React from 'react'

import Navbar from '../Navbar'
import Footer from '../Footer'

// style
import './style.css'

const Layout = ({ navbarHome, navbarGame, navbarEmty, children }) => {
    return (
        <>
            <Navbar
                navbarHome={navbarHome}
                navbarGame={navbarGame}
                navbarEmty={navbarEmty}
            />
            <div className="layout">
                {children}
            </div>
            <Footer />
        </>
    )
}

export default Layout
