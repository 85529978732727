import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { setDefaultLang } from './language'

// pages
import Home from './pages/Home'
import Faq from './pages/Faq'
import Game from './pages/Game'
import Category from './pages/Category'

// style
import './assets/css/slick.css'
import './assets/css/bootstrap-grid.css'
import './assets/css/bootstrap-modal.css'
import './assets/css/main.css'

const App = () => {

	useEffect(() => {
		setDefaultLang()
	}, [])

	return (
		<>
			<Router>
				<Switch>
					<Route path="/" exact component={Home} />
					<Route path="/faq" exact component={Faq} />
					<Route path="/game/:url" exact component={Game} />
					<Route path="/category/:url" exact component={Category} />
				</Switch>
			</Router>
		</>
	)
}

export default App