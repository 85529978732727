import React from 'react'

import './style.css'

const Group = ({ id, icon, title, description, children }) => {
    return (
        <div className='group' id={id}>
            <div className='group__title-icon-wrapper'>
                {icon && <img src={icon} alt="icon" className="group__icon" />}
                <h2 className='group__title'>{title}</h2>
            </div>
            <p className='group__description'>{description}</p>
            <div className='group__children'>
                {children}
            </div>
        </div>
    )
}

export default Group
