import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { GAMES_COLLECTION } from '../../firebase'
import { cpoyrightLanguageTitle, discountLanguageTitle, getDefaultLang, mostPopularLanguageTitle, newReleasesLanguageTitle, socialNetworksLanguageTitle } from '../../language'

import './style.css'

import logo from './assets/logo.svg'

const Footer = () => {

    const defaultLang = getDefaultLang()

    const [mostPopular, setMostPopular] = useState([])
    const [newReleases, setNewReleases] = useState([])
    const [discount, setDiscount] = useState([])

    useEffect(() => {

        const getMostPopular = async () => {

            const snapshot = await GAMES_COLLECTION
                .where('category', 'array-contains-any', ['most-popular'])
                .orderBy('date', 'desc')
                .limit(6)
                .get()

            const items = [];

            snapshot.forEach(doc => {

                const data = doc.data()

                const _id = data._id
                const url = data.url

                let title = ''

                if (defaultLang === 'en') {
                    title = data.title_en
                }

                if (defaultLang === 'sr') {
                    title = data.title_sr
                }

                items.push({ _id, url, title })
            })

            setMostPopular(items)
        }

        getMostPopular()

        const getNewReleases = async () => {

            const snapshot = await GAMES_COLLECTION
                .orderBy('release', 'desc')
                .limit(6)
                .get()

            const items = [];

            snapshot.forEach(doc => {

                const data = doc.data()

                const _id = data._id
                const url = data.url

                let title = ''

                if (defaultLang === 'en') {
                    title = data.title_en
                }

                if (defaultLang === 'sr') {
                    title = data.title_sr
                }

                items.push({ _id, url, title })
            })

            setNewReleases(items)
        }

        getNewReleases()

        const getDiscount = async () => {

            const snapshot = await GAMES_COLLECTION
                .where('category', 'array-contains-any', ['discount'])
                .orderBy('date', 'desc')
                .limit(6)
                .get()

            const items = [];

            snapshot.forEach(doc => {

                const data = doc.data()

                const _id = data._id
                const url = data.url

                let title = ''

                if (defaultLang === 'en') {
                    title = data.title_en
                }

                if (defaultLang === 'sr') {
                    title = data.title_sr
                }

                items.push({ _id, url, title })
            })

            setDiscount(items)
        }

        getDiscount()

    }, [])

    return (
        <>
            <div className='footer'>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={3}>
                            <div className='footer__group'>
                                <h2 className='footer__title'>{mostPopularLanguageTitle}</h2>
                                {
                                    mostPopular.length > 0 ?
                                        mostPopular.map(item => {
                                            return (
                                                <Link key={item._id} to={`/game/${item.url}`} className='footer__link'>
                                                    {item.title}
                                                </Link>
                                            )
                                        })
                                        :
                                        <p className='footer__link'>-</p>
                                }
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={3}>
                            <div className='footer__group'>
                                <h2 className='footer__title'>{newReleasesLanguageTitle}</h2>
                                {
                                    newReleases.length > 0 ?
                                        newReleases.map(item => {
                                            return (
                                                <Link key={item._id} to={`/game/${item.url}`} className='footer__link'>
                                                    {item.title}
                                                </Link>
                                            )
                                        })
                                        :
                                        <p className='footer__link'>-</p>
                                }
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={3}>
                            <div className='footer__group'>
                                <h2 className='footer__title'>{discountLanguageTitle}</h2>
                                {
                                    discount.length > 0 ?
                                        discount.map(item => {
                                            return (
                                                <Link key={item._id} to={`/game/${item.url}`} className='footer__link'>
                                                    {item.title}
                                                </Link>
                                            )
                                        })
                                        :
                                        <p className='footer__link'>-</p>
                                }
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={3}>
                            <div className='footer__group'>
                                <h2 className='footer__title'>{socialNetworksLanguageTitle}</h2>
                                <a className='footer__link' href="https://www.instagram.com/ajnason1_soniteka/" target="_blank" rel="noopener noreferrer">Instagram</a>
                                <a className='footer__link' href="https://www.facebook.com/AJ-NA-SON1-PS4-igraonica-i-kafe-bar-Banjaluka-364225951100100/" target="_blank" rel="noopener noreferrer">Facebook</a>
                                <a className='footer__link' href="mailto:rezervacije@soniteka.com" target="_blank" rel="noopener noreferrer">E-mail</a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='copyright'>
                <Container>
                    <Row className="copyright__row">
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <div className="copyright__wrapper-image">
                                <img className="copyright__image" src={logo} alt="logo soniteka" />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <p className="copyright__text">
                                {cpoyrightLanguageTitle}
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Footer
