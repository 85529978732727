import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { faqLanguageTitle, getDefaultLang, showMoreLanguageTitle } from '../../language'
import { CATEGORIES_COLLECTION, FAQ_COLLECTION, GAMES_COLLECTION } from '../../firebase'
import { categoryFilterSelector, monthFilterSelector, searchFilterSelector, yearFilterSelector } from '../../redux/selectors'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import moment from 'moment'

import Content from '../../components/Content'
import Filter from '../../components/Filter'
import CardGame from '../../components/CardGame'
import CardSlider from '../../components/CardSlider'
import CardSidebar from '../../components/CardSidebar'
import ButtonLoadMore from '../../components/ButtonLoadMore'
import IntroVideo from '../../components/IntroVideo/IntroVideo'
import Slider from '../../components/Slider'
import Loader from '../../components/Loader'
import Banner from '../../components/Banner'
import Layout from '../../components/Layout'
import RowScroll from '../../components/RowScroll'

const Category = () => {

    const { url } = useParams()

    const searchFilter = useSelector(searchFilterSelector)
    const categoryFilter = useSelector(categoryFilterSelector)
    const monthFilter = useSelector(monthFilterSelector)
    const yearFilter = useSelector(yearFilterSelector)

    const defaultLang = getDefaultLang()

    const [pagination, setPagination] = useState(12)

    const [faq, setFaq] = useState([])
    const [games, setGames] = useState([])
    const [game, setGame] = useState([])
    const [categories, setCategories] = useState([])


    useEffect(() => {

        const getFaq = async () => {

            const snapshot = await FAQ_COLLECTION
                .orderBy('date', 'desc')
                .limit(4)
                .get()

            const items = []

            snapshot.forEach(doc => {

                const data = doc.data()

                const _id = data._id

                let title = ''

                if (defaultLang === 'en') {
                    title = data.title_en
                }

                if (defaultLang === 'sr') {
                    title = data.title_sr
                }

                items.push({ _id, title })
            })

            setFaq(items)
        }

        getFaq()

        const getGame = async () => {

            const snapshot = await GAMES_COLLECTION
                .where('slider', '==', true)
                .orderBy('date', 'desc')
                .get()

            const items = []

            snapshot.forEach(doc => {

                const data = doc.data()

                const _id = data._id
                const date = data.date
                const image = data.image
                const poster = data.poster
                const slider = data.slider
                const url = data.url
                const video = data.video

                let description = ''
                let title = ''

                if (defaultLang === 'en') {
                    description = data.description_en
                    title = data.title_en
                }

                if (defaultLang === 'sr') {
                    description = data.description_sr
                    title = data.title_sr
                }

                items.push({ _id, date, image, poster, slider, url, description, title, video })
            })

            setGame(items)
        }

        getGame()

    }, [])

    useEffect(() => {

        const getCategories = async () => {

            const snapshot = await CATEGORIES_COLLECTION
                .where('category', '==', url)
                .get()

            const items = []

            snapshot.forEach(doc => {

                const data = doc.data()

                const _id = data._id
                const category = data.category

                let title = ''

                if (defaultLang === 'en') {
                    title = data.title_en
                }

                if (defaultLang === 'sr') {
                    title = data.title_sr
                }

                items.push({ _id, category, title })
            })

            setCategories(items)
        }

        getCategories()

        const getGames = async () => {

            const snapshot = await GAMES_COLLECTION
                .where('category', 'array-contains-any', [url])
                .orderBy('date', 'desc')
                .get()

            const items = []

            snapshot.forEach(doc => {

                const data = doc.data()

                const _id = data._id
                const category = data.category
                const date = data.date
                const image = data.image
                const release = data.release
                const url = data.url

                let title = ''

                if (defaultLang === 'en') {
                    title = data.title_en
                }

                if (defaultLang === 'sr') {
                    title = data.title_sr
                }

                items.push({ _id, category, date, image, release, url, title })
            })

            setGames(items)
        }

        getGames()

        window.scrollTo(0, 0)
    }, [window.location.pathname])

    const filterGamesLg = games

        .filter((item) => {

            let canReturnItem = false

            const month = moment(item?.release.toDate()).format('MM')
            const year = moment(item?.release.toDate()).format('YYYY')

            const searchLowerCase = searchFilter.toLowerCase()

            if (
                (item?.title.toLowerCase().includes(searchLowerCase)) &&
                (item?.category.includes(categoryFilter)) &&
                (month.includes(monthFilter)) &&
                (year.includes(yearFilter))
            ) {
                canReturnItem = true
            }

            return canReturnItem
        })

    const filterGamesSm = games

        .filter((item) => {

            let canReturnItem = false

            const month = moment(item?.release.toDate()).format('MM')
            const year = moment(item?.release.toDate()).format('YYYY')

            const searchLowerCase = searchFilter.toLowerCase()

            if (
                (item?.title.toLowerCase().includes(searchLowerCase)) &&
                (month.includes(monthFilter)) &&
                (year.includes(yearFilter))
            ) {
                canReturnItem = true
            }

            return canReturnItem
        })

    return (
        <Layout navbarHome>
            <Loader />
            <Content home>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={4}>
                            <Filter />
                            <div className='display-block-lg'>
                                <Slider>
                                    {
                                        game
                                            .map(item => {
                                                return (
                                                    <div key={item._id}>
                                                        <CardSlider
                                                            url={`/game/${item.url}`}
                                                            image={item.image}
                                                            title={item.title}
                                                            description={item.description}
                                                        />
                                                    </div>
                                                )
                                            })
                                    }
                                </Slider>
                                <CardSidebar
                                    title={faqLanguageTitle}
                                    titleButton={showMoreLanguageTitle}
                                    to='/faq'
                                >
                                    {
                                        faq
                                            .map(item => {
                                                return (
                                                    <Link key={item._id} to='/faq'>
                                                        {item.title}
                                                    </Link>
                                                )
                                            })
                                    }
                                </CardSidebar>
                                <Banner />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={8}>

                            <div className='display-block-lg'>

                                {
                                    filterGamesSm.length > 0 &&
                                    <RowScroll title={categories.map(item => item.title)}>
                                        {
                                            filterGamesLg
                                                .slice(0, pagination)
                                                .map(item => {
                                                    return (
                                                        <>
                                                            <Col key={item._id} xs={12} sm={12} md={12} lg={3}>
                                                                <CardGame
                                                                    url={`/game/${item.url}`}
                                                                    image={item.image}
                                                                    title={item.title}
                                                                    release={item.release ? moment(item.release.toDate()).format('DD/MM/YYYY') : false}
                                                                />
                                                            </Col>
                                                        </>
                                                    )
                                                })
                                        }
                                    </RowScroll>
                                }

                                {
                                    filterGamesLg.length > pagination &&
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <ButtonLoadMore
                                                onClick={() => setPagination(pagination + 12)}
                                                itemsPerPage={pagination}
                                                totalItems={filterGamesLg.length}
                                            />
                                        </Col>
                                    </Row>
                                }

                            </div>

                            <div className='display-block-sm'>
                                {
                                    filterGamesSm.length > 0 &&
                                    <RowScroll title={categories.map(item => item.title)}>
                                        {
                                            filterGamesSm
                                                .slice(0, pagination)
                                                .map(itemGame => {
                                                    return (
                                                        <>
                                                            <Col key={itemGame._id} xs={6} sm={6} md={6} lg={6}>
                                                                <CardGame
                                                                    smallImage
                                                                    url={`/game/${itemGame.url}`}
                                                                    image={itemGame.image}
                                                                    title={itemGame.title}
                                                                    release={itemGame.release ? moment(itemGame.release.toDate()).format('DD/MM/YYYY') : false}
                                                                />
                                                            </Col>
                                                        </>
                                                    )
                                                })
                                        }
                                    </RowScroll>
                                }

                                {
                                    filterGamesLg.length > pagination &&
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <ButtonLoadMore
                                                onClick={() => setPagination(pagination + 12)}
                                                itemsPerPage={pagination}
                                                totalItems={filterGamesLg.length}
                                            />
                                        </Col>
                                    </Row>
                                }

                            </div>

                            <div style={{ borderBottom: '1px solid #e3e3e9', marginBottom: 30 }}></div>

                            <div className='display-block-sm'>
                                <Slider>
                                    {
                                        game
                                            .map(item => {
                                                return (
                                                    <div key={item._id}>
                                                        <CardSlider
                                                            url={`/game/${item.url}`}
                                                            image={item.image}
                                                            title={item.title}
                                                            description={item.description}
                                                        />
                                                    </div>
                                                )
                                            })
                                    }
                                </Slider>
                                <CardSidebar
                                    title={faqLanguageTitle}
                                    titleButton={showMoreLanguageTitle}
                                    to='/faq'
                                >
                                    {
                                        faq
                                            .map(item => {
                                                return (
                                                    <Link key={item._id} to='/faq'>
                                                        {item.title}
                                                    </Link>
                                                )
                                            })
                                    }
                                </CardSidebar>
                                <Banner />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Content>
        </Layout >
    )
}

export default Category
