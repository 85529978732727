import { combineReducers } from 'redux';

// Filter

const initialStateFilter = {
    search: '',
    category: 'all-categories',
    month: '',
    year: '',
    show: false
}

export const filterReducer = (state = initialStateFilter, { type, payload }) => {
    switch (type) {

        case 'FILTER':
            return { ...state, ...payload }

        default:
            return state
    }
}


// All reducers

const allReducers = combineReducers({
    filterReducer
});

export default allReducers;