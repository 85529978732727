import React, { useEffect, useState } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap'
import { buyLanguageTitle, categoriesLanguageTitle, contactLanguageTitle, descriptionLanguageTitle, detailsLanguageTitle, enterContactLanguageTitle, enterYourNameLanguageTitle, faqLanguageTitle, getDefaultLang, iAcceptTheTermsOfPurchaseLanguageTitle, imageLanguageTitle, methodOfPaymentLanguageTitle, nameLanguageTitle, noteBuyLanguageTitle, noteRentLanguageTitle, offerOneBuyLanguageTitle, offerOneRentLanguageTitle, offerThreeBuyLanguageTitle, offerTwoBuyLanguageTitle, offerTwoRentLanguageTitle, paymentSlipLanguageTitle, paypalLanguageTitle, priceLanguageTitle, ratingLanguageTitle, releasedLanguageTitle, rentLanguageTitle, selectLanguageTitle, showMoreLanguageTitle, specificationsAndRequirementsLanguageTitle, stablecoinsLanguageTitle, xbonLanguageTitle } from '../../language'
import { FAQ_COLLECTION, GAMES_COLLECTION, ORDERS_COLLECTION, serverTimestamp } from '../../firebase'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router';
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';

import Content from '../../components/Content'
import CardSlider from '../../components/CardSlider'
import CardSidebar from '../../components/CardSidebar'
import Group from '../../components/Group'
import Slider from '../../components/Slider'
import IntroVideo from '../../components/IntroVideo'
import Loader from '../../components/Loader'
import Banner from '../../components/Banner'
import Layout from '../../components/Layout'

import imageIcon from './assets/image-icon.svg'
import descriptionIcon from './assets/description-icon.svg'
import categoryIcon from './assets/category-icon.svg'
import iframeIcon from './assets/iframe-icon.svg'
import releaseIcon from './assets/release-icon.svg'
import ratingIcon from './assets/rating-icon.svg'
import detailsIcon from './assets/details-icon.svg'
import closeIcon from './assets/close.svg'
import priceIcon from './assets/price-icon.svg'
import specIcon from './assets/spec-icon.svg'

import './style.css'

const Game = () => {

    const INITIAL_STATE = {
        _id: '',
        contact: '',
        date: serverTimestamp.now(),
        done: false,
        method_of_payment: '',
        name: '',
        price: ''
    }

    const { url } = useParams()

    const defaultLang = getDefaultLang()

    const [faq, setFaq] = useState([])
    const [games, setGames] = useState([])
    const [game, setGame] = useState({})

    const [buyModal, setBuyModal] = useState(false)
    const [rentModal, setRentModal] = useState(false)

    const [add, setAdd] = useState(INITIAL_STATE)

    const [isCheckedBuy, setIsCheckedBuy] = useState(false)
    const [isCheckedRent, setIsCheckedRent] = useState(false)

    const handleAdd = async (e) => {

        e.preventDefault();

        const _id = uuidv4();

        await ORDERS_COLLECTION.doc(_id).set({
            _id: _id,
            contact: add.contact,
            date: add.date,
            done: add.done,
            game: game.title,
            method_of_payment: add.method_of_payment,
            name: add.name,
            price: add.price
        })
            .then(() => {
                alert("Uspješno ste izvršili narudžbu. Uskoro ćete biti kontaktirani od strane administratora stranice.")
                setBuyModal(false)
                setRentModal(false)
            })
            .catch((error) => {
                alert(error.message)
            })
    }

    useEffect(() => {

        const getFaq = async () => {

            const snapshot = await FAQ_COLLECTION
                .orderBy('date', 'desc')
                .limit(4)
                .get()

            const items = [];

            snapshot.forEach(doc => {

                const data = doc.data()

                const _id = data._id

                let title = ''

                if (defaultLang === 'en') {
                    title = data.title_en
                }

                if (defaultLang === 'sr') {
                    title = data.title_sr
                }

                items.push({ _id, title })
            })

            setFaq(items)
        }

        getFaq()

        const getGames = async () => {

            const snapshot = await GAMES_COLLECTION
                .where('slider', '==', true)
                .orderBy('date', 'desc')
                .get()

            const items = [];

            snapshot.forEach(doc => {

                const data = doc.data()

                const _id = data._id
                const image = data.image
                const url = data.url

                let description = ''
                let title = ''

                if (defaultLang === 'en') {
                    description = data.description_en
                    title = data.title_en
                }

                if (defaultLang === 'sr') {
                    description = data.description_sr
                    title = data.title_sr
                }

                items.push({ _id, image, url, description, title })
            })

            setGames(items)
        }

        getGames()

    }, [])

    useEffect(() => {

        const getGame = async () => {

            const snapshot = await GAMES_COLLECTION
                .where('url', '==', url)
                .get()

            snapshot.forEach(doc => {

                const data = doc.data()

                const _id = data._id
                const buyOne = data.buy_one
                const buyTwo = data.buy_two
                const buyThree = data.buy_three
                const category = data.category
                const date = data.date
                const discount = data.discount
                const iframe = data.iframe
                const image = data.image
                const poster = data.poster
                const rating = data.rating
                const release = data.release
                const rentOne = data.rent_one
                const rentTwo = data.rent_two
                const slider = data.slider
                const url = data.url
                const video = data.video

                let description = ''
                let details = ''
                let specificationsAndRequirements = ''
                let title = ''

                if (defaultLang === 'en') {
                    description = data.description_en
                    details = data.details_en
                    specificationsAndRequirements = data.specifications_and_requirements_en
                    title = data.title_en
                }

                if (defaultLang === 'sr') {
                    description = data.description_sr
                    details = data.details_sr
                    specificationsAndRequirements = data.specifications_and_requirements_sr
                    title = data.title_sr
                }

                setGame({ _id, buyOne, buyTwo, buyThree, category, date, discount, iframe, image, poster, rating, release, rentOne, rentTwo, slider, url, description, details, title, specificationsAndRequirements, video })
            })

        }

        getGame()

        window.scrollTo(0, 0)

    }, [window.location.pathname])

    return (
        <Layout navbarHome>
            <Loader />
            <IntroVideo
                key={game._id}
                titleButtonOne={`${buyLanguageTitle}`}
                titleButtonTwo={`${rentLanguageTitle}`}
                title={game.title}
                poster={game.poster}
                video={game.video}
                onClickOne={() => setBuyModal(!buyModal)}
                onClickTwo={() => setRentModal(!rentModal)}
            />
            <Content>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={4}>
                            <div className='display-block-lg'>
                                <Slider>
                                    {
                                        games
                                            .map(item => {
                                                return (
                                                    <div key={item._id}>
                                                        <CardSlider
                                                            url={`/game/${item.url}`}
                                                            image={item.image}
                                                            title={item.title}
                                                            description={item.description}
                                                        />
                                                    </div>
                                                )
                                            })
                                    }
                                </Slider>
                                <CardSidebar
                                    title={faqLanguageTitle}
                                    titleButton={showMoreLanguageTitle}
                                    to='/faq'
                                >
                                    {
                                        faq
                                            .map(item => {
                                                return (
                                                    <Link key={item._id} to='/faq'>
                                                        {item.title}
                                                    </Link>
                                                )
                                            })
                                    }
                                </CardSidebar>
                                <Banner />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={8}>
                            <Group
                                id='image'
                                icon={imageIcon}
                                title={imageLanguageTitle}
                            >
                                <img src={game.image} alt="game" />
                            </Group>
                            <Group
                                id='description'
                                icon={descriptionIcon}
                                title={descriptionLanguageTitle}
                                description={game.description}
                            />
                            <Group
                                id='price'
                                icon={priceIcon}
                                title={priceLanguageTitle}
                            >
                                <p>{buyLanguageTitle} ({offerOneBuyLanguageTitle}) {game.buyOne}KM</p>
                                <p>{buyLanguageTitle} ({offerTwoBuyLanguageTitle}) {game.buyTwo}KM</p>
                                <p>{buyLanguageTitle} ({offerThreeBuyLanguageTitle}) {game.buyThree}KM</p>
                                <p>{rentLanguageTitle} ({offerOneRentLanguageTitle}) {game.rentOne}KM</p>
                                <p>{rentLanguageTitle} ({offerTwoRentLanguageTitle}) {game.rentTwo}KM</p>
                            </Group>
                            <Group
                                id='categories'
                                icon={categoryIcon}
                                title={categoriesLanguageTitle}
                            >
                                {game.category ? game.category.map(game => <p key={game._id}>{game}</p>) : null}
                            </Group>
                            <Group
                                id='media'
                                icon={iframeIcon}
                                title='Media'
                            >
                                <iframe width="100%" height="100%" src={game.iframe} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </Group>
                            <Group
                                id='released'
                                icon={releaseIcon}
                                title={releasedLanguageTitle}
                                description={game.release ? moment(game.release.toDate()).format('DD/MM/YYYY') : null}
                            />
                            <Group
                                id='rating'
                                icon={ratingIcon}
                                title={ratingLanguageTitle}
                                description={game.rating}
                            />
                            <Group
                                id='details'
                                icon={detailsIcon}
                                title={detailsLanguageTitle}
                                description={game.details}
                            />
                            <Group
                                id='specifications-and-requirements'
                                icon={specIcon}
                                title={specificationsAndRequirementsLanguageTitle}
                                description={game.specificationsAndRequirements}
                            />


                            <div className='display-block-sm'>
                                <Slider>
                                    {
                                        games
                                            .map(item => {
                                                return (
                                                    <div key={item._id}>
                                                        <CardSlider
                                                            url={`/game/${item.url}`}
                                                            image={item.image}
                                                            title={item.title}
                                                            description={item.description}
                                                        />
                                                    </div>
                                                )
                                            })
                                    }
                                </Slider>
                                <CardSidebar
                                    title={faqLanguageTitle}
                                    titleButton={showMoreLanguageTitle}
                                    to='/faq'
                                >
                                    {
                                        faq
                                            .map(item => {
                                                return (
                                                    <Link key={item._id} to='/faq'>
                                                        {item.title}
                                                    </Link>
                                                )
                                            })
                                    }
                                </CardSidebar>
                                <Banner />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Content>
            <Modal show={buyModal} centered onHide={() => setBuyModal(false)}>
                <Modal.Body>
                    <div className='modal-game'>
                        <img className='modal-game__icon-close' src={closeIcon} alt="close" onClick={() => setBuyModal(!buyModal)} />

                        <h2 className='modal-game__title'>
                            {game.title}
                        </h2>
                        <form onSubmit={handleAdd}>

                            <label className='modal-game__label'>{nameLanguageTitle}</label>
                            <input className='modal-game__input' type="text" value={game.name} onChange={(e) => { setAdd({ ...add, name: e.target.value }) }} placeholder={enterYourNameLanguageTitle} required />

                            <label className='modal-game__label'>{contactLanguageTitle}</label>
                            <input className='modal-game__input' type="text" value={game.contact} onChange={(e) => { setAdd({ ...add, contact: e.target.value }) }} placeholder={enterContactLanguageTitle} required />

                            <label className='modal-game__label'>{methodOfPaymentLanguageTitle}</label>
                            <select className='modal-game__select' value={game.method_of_payment} onChange={(e) => { setAdd({ ...add, method_of_payment: e.target.value }) }} required>
                                <option value="" selected disabled>{selectLanguageTitle}</option>
                                <option value='Uplatnica (banka ili posta)'>{paymentSlipLanguageTitle}</option>
                                <option value='Paypal (+5% provizija)'>{paypalLanguageTitle}</option>
                                <option value='Xbon (+5% provizija)'>{xbonLanguageTitle}</option>
                                <option value='Kriptovalute (stablecoins)'>{stablecoinsLanguageTitle}</option>
                            </select>

                            <label className='modal-game__label'>{priceLanguageTitle}</label>
                            <select className='modal-game__select' value={game.price} onChange={(e) => { setAdd({ ...add, price: e.target.value }) }} required>
                                <option value="" selected disabled>{selectLanguageTitle}</option>
                                <option value={`${offerOneBuyLanguageTitle} ${game.buyOne}KM - ${buyLanguageTitle}`}>{offerOneBuyLanguageTitle} {game.buyOne}KM</option>
                                <option value={`${offerTwoBuyLanguageTitle} ${game.buyTwo}KM - ${buyLanguageTitle}`}>{offerTwoBuyLanguageTitle} {game.buyTwo}KM</option>
                                <option value={`${offerThreeBuyLanguageTitle} ${game.buyThree}KM - ${buyLanguageTitle}`}>{offerThreeBuyLanguageTitle} {game.buyThree}KM</option>
                            </select>

                            <label className='modal-game__label'>
                                <input
                                    onChange={(e) => setIsCheckedBuy(e.currentTarget.checked)}
                                    checked={isCheckedBuy}
                                    type="checkbox"
                                    value="true"
                                    style={{ margin: '0 8px 15px 0' }}
                                    required
                                />
                                {iAcceptTheTermsOfPurchaseLanguageTitle}
                            </label>

                            <button className='modal-game__button' type="submit">
                                {buyLanguageTitle}
                            </button>

                            {isCheckedBuy && <p className='modal-game__note'>{noteBuyLanguageTitle}</p>}

                        </form>

                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={rentModal} centered onHide={() => setRentModal(false)}>
                <Modal.Body>
                    <div className='modal-game'>
                        <img className='modal-game__icon-close' src={closeIcon} alt="close" onClick={() => setRentModal(!rentModal)} />

                        <h2 className='modal-game__title'>
                            {game.title}
                        </h2>
                        <form onSubmit={handleAdd}>

                            <label className='modal-game__label'>{nameLanguageTitle}</label>
                            <input className='modal-game__input' type="text" placeholder={enterYourNameLanguageTitle} required value={add.name} onChange={(e) => { setAdd({ ...add, name: e.target.value }) }} />

                            <label className='modal-game__label'>{contactLanguageTitle}</label>
                            <input className='modal-game__input' type="text" placeholder={enterContactLanguageTitle} required value={add.contact} onChange={(e) => { setAdd({ ...add, contact: e.target.value }) }} />

                            <label className='modal-game__label'>{methodOfPaymentLanguageTitle}</label>
                            <select className='modal-game__select' required value={add.method_of_payment} onChange={(e) => { setAdd({ ...add, method_of_payment: e.target.value }) }}>
                                <option value="" selected disabled>{selectLanguageTitle}</option>
                                <option value='Uplatnica (banka ili posta)'>{paymentSlipLanguageTitle}</option>
                                <option value='Paypal (+5% provizija)'>{paypalLanguageTitle}</option>
                                <option value='Xbon (+5% provizija)'>{xbonLanguageTitle}</option>
                                <option value='Kriptovalute (stablecoins)'>{stablecoinsLanguageTitle}</option>
                            </select>

                            <label className='modal-game__label'>{priceLanguageTitle}</label>
                            <select className='modal-game__select' required value={add.price} onChange={(e) => { setAdd({ ...add, price: e.target.value }) }}>
                                <option value="" selected disabled>{selectLanguageTitle}</option>
                                <option value={`${offerOneRentLanguageTitle} ${game.rentOne}KM - ${rentLanguageTitle}`}>{offerOneRentLanguageTitle} {game.rentOne}KM</option>
                                <option value={`${offerTwoRentLanguageTitle} ${game.rentTwo}KM - ${rentLanguageTitle}`}>{offerTwoRentLanguageTitle} {game.rentTwo}KM</option>
                            </select>

                            <label className='modal-game__label'>
                                <input
                                    onChange={(e) => setIsCheckedRent(e.currentTarget.checked)}
                                    checked={isCheckedRent}
                                    type="checkbox"
                                    value="true"
                                    style={{ margin: '0 8px 15px 0' }}
                                    required
                                />
                                {iAcceptTheTermsOfPurchaseLanguageTitle}
                            </label>

                            <button className='modal-game__button' type="submit">
                                {rentLanguageTitle}
                            </button>

                            {isCheckedRent && <p className='modal-game__note'>{noteRentLanguageTitle}</p>}

                        </form>

                    </div>
                </Modal.Body>
            </Modal>
        </Layout>
    )
}

export default Game
