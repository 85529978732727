import React, { useEffect, useState } from 'react'
import { Container, Modal } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { backLanguageTitle, contactLanguageTitle, faqLanguageTitle, getDefaultLang, homeLanguageTitle, setLang } from '../../language'
import { CATEGORIES_COLLECTION, GAMES_COLLECTION } from '../../firebase'

import './style.css'

import logo from './assets/logo.svg'
import menu from './assets/menu.svg'
import close from './assets/close.svg'
import back from './assets/back.svg'

// const navbarGameArray = [
//     { title: imageLanguageTitle, id: 'image' },
//     { title: descriptionLanguageTitle, id: 'description' },
//     { title: priceLanguageTitle, id: 'price' },
//     { title: categoriesLanguageTitle, id: 'categories' },
//     { title: 'Media', id: 'media' },
//     { title: releasedLanguageTitle, id: 'released' },
//     { title: ratingLanguageTitle, id: 'rating' },
//     { title: detailsLanguageTitle, id: 'details' },
//     { title: specificationsAndRequirementsLanguageTitle, id: 'specifications-and-requirements' }
// ]

const Navbar = ({ navbarHome, navbarGame, navbarEmty }) => {

    const history = useHistory()

    const defaultLang = getDefaultLang()

    const [openNavbarSm, setOpenNavbarSm] = useState(false)

    const [games, setGames] = useState([])

    const [categories, setCategories] = useState([])

    useEffect(() => {

        const getGames = async () => {

            const snapshot = await GAMES_COLLECTION.get()

            const items = [];

            snapshot.forEach(doc => {

                const data = doc.data()

                const _id = data._id
                const category = data.category

                items.push({ _id, category })
            })

            setGames(items)
        }

        getGames()

        const getCategories = async () => {

            const snapshot = await CATEGORIES_COLLECTION.get()

            const items = []

            snapshot.forEach(doc => {

                const data = doc.data()

                const _id = data._id
                const category = data.category

                let title = ''

                if (defaultLang === 'en') {
                    title = data.title_en
                }

                if (defaultLang === 'sr') {
                    title = data.title_sr
                }

                items.push({ _id, category, title })
            })

            setCategories(items)
        }

        getCategories()

    }, []);

    return (
        <>
            <div className="navbar-lg">
                <Container>
                    <div className="navbar-lg__wrapper">
                        <Link to='/'>
                            <img className="navbar-lg__image" src={logo} alt="logo soniteka" />
                        </Link>
                        <div>
                            <Link className="navbar-lg__link" to='/'>{homeLanguageTitle}</Link>
                            <Link className="navbar-lg__link" to='/faq'>{faqLanguageTitle}</Link>
                            <a className="navbar-lg__link" href='mailto:rezervacije@soniteka.com'>{contactLanguageTitle}</a>
                            <div className="navbar-lg__language-wrapper">
                                {
                                    defaultLang === 'sr'
                                        ?
                                        <button className='navbar-lg__language-button' onClick={() => setLang('en')}>English</button>
                                        :
                                        <button className='navbar-lg__language-button' onClick={() => setLang('sr')}>Srpski</button>
                                }
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="navbar-close-sm">
                <Container>
                    <div className="navbar-close-sm__wrapper">
                        <Link to='/'>
                            <img className="navbar-close-sm__image" src={logo} alt="logo soniteka" />
                        </Link>
                        <div>
                            <img className="navbar-close-sm__menu" src={menu} alt="menu" onClick={() => setOpenNavbarSm(!openNavbarSm)} />
                        </div>
                    </div>
                </Container>
            </div>

            <div className="sub-navbar">
                <Container>
                    <div className="sub-navbar__wrapper">
                        {
                            navbarHome
                            &&
                            categories.map(itemCategory => {
                                return (
                                    <React.Fragment key={itemCategory._id}>
                                        {
                                            games.filter(itemGame => itemGame.category.includes(itemCategory.category)).length > 0
                                                ?
                                                <Link
                                                    key={itemCategory._id}
                                                    className='sub-navbar__link'
                                                    to={`/category/${itemCategory.category}`}
                                                >
                                                    {itemCategory.title}
                                                </Link>
                                                :
                                                null
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                        {
                            navbarEmty
                            &&
                            <div className='sub-navbar__link' onClick={() => history.goBack()}>
                                <img className="sub-navbar__link-back" src={back} alt="back" />{backLanguageTitle}
                            </div>
                        }
                    </div>
                </Container>
            </div>
            <Modal
                show={openNavbarSm}
                fullscreen
                scrollable={false}
                onHide={() => setOpenNavbarSm(false)}
            >
                <Modal.Body>
                    <div className='navbar-open-sm'>
                        <img className="navbar-open-sm__close" src={close} alt="close" onClick={() => setOpenNavbarSm(!openNavbarSm)} />
                        <Link className="navbar-open-lg__link" to='/' onClick={() => setOpenNavbarSm(!openNavbarSm)}>{homeLanguageTitle}</Link>
                        <Link className="navbar-open-lg__link" to='/faq' onClick={() => setOpenNavbarSm(!openNavbarSm)}>{faqLanguageTitle}</Link>
                        <a className="navbar-open-lg__link" href='mailto:rezervacije@soniteka.com' onClick={() => setOpenNavbarSm(!openNavbarSm)}>{contactLanguageTitle}</a>
                        <div className='navbar-open-sm__language'>
                            {
                                defaultLang === 'sr'
                                    ?
                                    <button className='navbar-open-sm__language-button' onClick={() => { setLang('en'); setOpenNavbarSm(!openNavbarSm) }}>Switch to english language</button>
                                    :
                                    <button className='navbar-open-sm__language-button' onClick={() => { setLang('sr'); setOpenNavbarSm(!openNavbarSm) }}>Prebaci na srpski jezik</button>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Navbar