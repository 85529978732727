import React from 'react'
import { Link } from 'react-router-dom'
import { showMoreLanguageTitle } from '../../language'

import './style.css'

const CardSlider = ({ url, title, description, image }) => {
    return (
        <>
            <div className='card-slider' style={{ backgroundImage: `linear-gradient(to top, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.9) 30%, #fff 100%), url(${image})` }}>
                <h2 className='card-slider__title'>
                    {title}
                </h2>
                <p className='card-slider__description'>
                    {description}
                </p>
                <Link to={url}>
                    <button className='card-slider__button'>
                        {showMoreLanguageTitle}
                    </button>
                </Link>
            </div>
        </>
    )
}

export default CardSlider
