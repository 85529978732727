import React from 'react'
import { Link } from 'react-router-dom'
import { releasedLanguageTitle } from '../../language'

import './style.css'

const CardGame = ({ url, image, title, release, smallImage }) => {
    return (
        <>
            <Link to={url} style={{ textDecoration: 'inherit', color: 'inherit' }}>
                <div className="card-game">
                    <img className={`${smallImage ? 'card-game__image--small' : 'card-game__image'}`} src={image} alt={title} />
                    <div className="card-game__wrapper">
                        <h2 className="card-game__title">
                            {title}
                        </h2>
                        {release && <p className='card-game__release'>{releasedLanguageTitle} {release}</p>}
                    </div>
                </div>
            </Link>
        </>
    )
}

export default CardGame
