import React from 'react'

import './style.css'

import banner from './assets/banner.png'

const Banner = () => {
    return (
        <>
            <a href="https://www.facebook.com/AJ-NA-SON1-PS4-igraonica-i-kafe-bar-Banjaluka-364225951100100/" target="_blank" rel="noopener noreferrer">
                <img className='banner' src={banner} alt="banner" />
            </a>
        </>
    )
}

export default Banner
