import React from 'react'
import { Container } from 'react-bootstrap'

import './style.css'

const IntroImage = ({ image, title }) => {
    return (
        <div
            className='intro-image'
            style={{ backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.5) 60%, transparent 100%), url(${image})` }}
        >
            <Container>
                <div className='intro-image__wrapper'>
                    <h2 className='intro-image__title'>
                        {title}
                    </h2>
                </div>
            </Container>
        </div>
    )
}

export default IntroImage
