import React from 'react'
import { Link } from 'react-router-dom'

import './style.css'

const CardSidebar = ({ title, children, titleButton, to }) => {
    return (
        <>
            <div className='card-sidebar'>
                <div className='card-sidebar__title'>
                    {title}
                </div>
                <div className='card-sidebar__children'>
                    {children}
                </div>
                {
                    to &&
                    <Link to={to}>
                        <button className='card-sidebar__button'>
                            {titleButton}
                        </button>
                    </Link>
                }
            </div>
        </>
    )
}

export default CardSidebar
